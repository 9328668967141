module.exports = {
	system: {
		Successqinggua: '清理挂单成功',
		failqinggua: '清理挂单失败',
		Qingliguadan: '清理挂单',
		wave_f_coin: '增币策略',
		bindEmailSuccess: '绑定邮箱成功',
		quedingbangding: '确定绑定',
		qingshuruyouxiang: '请输入邮箱',
		qingshuruyouxiang_new: '请输入新邮箱',
		qingshuruyouxiangzhanghao: '请输入邮箱账号',
		duo: '多',
		kong: '空',
		kuaijiekaihu: '快捷开户',
		allchecked: '全选',
		bian1: '币安',
		huobi1: '火必',
		zhiyingjiage: '止盈价格',
		manual_f_long: '手动策略多',
		manual_f_short: '手动策略空',
		beishu: '倍数',
		wangge_x: '解构马丁',
		asset_exchange: '资产兑换',
		qingshuruduihuanshuliang: '请输入兑换数额',
		wave_f_long: '海浪对冲多',
		wave_f_short: '海浪对冲空',
		yuerbuzu: '余额不足，请先充值!',
		shangxueyuan: '商学院',
		insufficient_gas: '燃料费不足',
		not_valid_membership: '用户不是有效期会员',
		wrong_params: '参数错误',
		failed_to_save: '保存设置失败',
		check_params: '请检查参数',
		empty_bot_id: '机器人id为空',
		wrong_bot_id: '机器人id错误',
		server_error: '服务器异常',
		yao_dejiangli: '邀请好友得奖励',
		yaozu_dejiangli: '邀请好友完成注册获得相应奖励',
		zhisunjiage: '止损价格',
		dangqianzijin: '当前资金',
		dangqianchicang: '当前持仓',
		jinri: '今日：',
		shouyipaihangbang: '收益排行榜',
		zhuanzhangjilu: '转账记录',
		tibijilu: '提币记录',
		tuichudenglu: '退出登录',
		kaitongvipxiangfuli: '开通会员享福利',
		lijikaitong: '立即开通',
		querenxiugai: '确认修改',
		queshaojiaoyimiyao: '缺少交易所私钥, 立即绑定?',
		lijibangdin: '立即绑定',
		jiaoyileixin: '交易类型',
		im_teacher: '我是老师',
		add_lianghua: '添加资管',
		ljyjsr: '累计佣金收入',
		xianshimingcheng: '显示名称',
		xuanzhejiaoyisuo: '选择交易所',
		jiaoyifangshi: '交易方式',
		zhanghuzongzichan: '账户总资产',
		weikaiqi: '未开启',
		jinxinzhong: '进行中',
		shenhezhong: '审核中',
		yichehui: '已撤回',
		yijujue: '已拒绝',
		xiugaixinxi: '修改信息',
		kaiqi: '开启',
		tingzhilianghua: '停止资管',
		chehuishenhe: '撤回审核',
		quanbutingzhi: '全部停止',
		quedingyaokaiqilianghuama: '确定要开启资管吗？',
		queding: '确定',
		zanshibuyao: '暂时不要',
		quedingyaotingzhilianghuama: '确定要停止资管吗？',
		tingzhihoubunengjixu: '停止后将不能继续带单',
		quedingyaochehuishenqingma: '确定要撤回申请吗？',
		quedingyaotingzhiall: '确定要停止全部资管吗？',
		success_che: '撤回成功',
		success_stop: '停止成功',
		success_open: '开启成功',
		tuoguanxiangqing: '托管详情',
		putongVIP: '普通会员',
		xiugaichushituoguanzijin: '修改初始托管资金',
		hdsy: '获得收益',
		syfd: '收益幅度',
		mrsy: '每日收益',
		xiugaichenggong: '修改成功',
		my_tuoguanxiangqing: '我的托管详情',
		tuoguancishu: '托管次数',
		daidanjiner: '带单金额',
		nidezhanghaohaiweibangding: '您的账号还没有绑定',
		pingtaizanwufashiyong: '平台暂无法使用托管功能',
		qubangding: '去绑定',
		xiugailianghua: '修改资管',
		qingshuruxianshimingcheng: '请输入显示名称',
		shoufeibili: '收费比例',
		qingshurushoufeibili: '请输入收费比例',
		zhanghuzongzichan: '账户总资产',
		qingshuruzhanghuzongzichan: '请输入账户总资产',
		tijioashenqing: '提交申请',
		shoufeilvbuhefa: '收费率不合法',
		
		fxtishi: '风险提示',
		agree: '同意',
		refuse: '拒绝',
		Contract: '张',
		xiugaifangxiang: '修改方向,原有持仓将被清仓',
		celue: '策略',
		zuoduo: '做多',
		zuokong: '做空',
		zuodanfangxiang: '做单方向',
		xianhuojiaoyi: '现货交易',
		heyuejiaoyi: '合约交易',
		kaifazhong: '开发中',
		new_price: '最新价',
		chengweilaoshi: '成为老师',
		woyaotuoguan: '我要托管',
		wodetuoguan: '我的托管',
		gensuizhe: '跟随者',
		onedayshouyi: '24小时收益',
		fenchengbili: '分成比例',
		zongdaidanrenshu: '总带单人数',
		nianhuashouyi: '年化收益',
		jinrishouyi: '今日收益USDT',
		leijishouyi: '累计收益USDT',
		zhengzaijinxin: '正在进行',
		yijieshu: '已结束',
		chushituoguanzijin: '初始托管资金',
		huodeshouyi: '获得收益USDT',
		tuoguanshijian: '托管时间',
		xiugaizijin: '修改资金',
		wodeleijishouyi: '我的累计收益',
		wodetuoguancishu: '我的托管次数',
		xiangqing: '详情',
		chushizijin: '初始资金',
		shouyilv: '收益率',
		ti_tingzhi: '停止托管后将不再跟随老师操作!',
		tuoguanzijin: '托管资金',
		zuidakeyongyue: '最大可使用余额',
		qingshuru: '请输入',
		quanbutuoguan: '全部托管',
		querentuoguan: '确认托管',
		cangwei: '仓位',
		jinsantianshouyi: '近三天日化收益',
		yuqinianhuashouyilv: '预期年化收益率',
		zhengzaidaidanrenshu: '正在带单人数',
		piliangtankuang: '仅针对已经勾选的所有币种进行操作',
		transfer_accounts: '转账成功',
		refund: '退款',
		global_reward_raise: '全球分红',
		trusteeship_brokerage_reduce: '托管佣金扣除',
		trusteeship_brokerage_add: '托管佣金',
		xianhuo_gas: '燃料费',
		deposit: '充值',
		team_reward: '团队奖',
		direct_promote: '直推奖',
		indirect_promote: '间推奖',
		flat_level_reward_raise: '平级奖',
		buy_active_code: '购买会员卡',
		withdraw: '提现',
		transfer: '转账',
		system_deposit: '系统存币',
		frozen_asset: '冻结资产',
		transfer_out_fee: '转出手续费',
		withdraw_deny: '拒绝提币',
		Delete: '删除',
		sure_to_delete: '确定删除吗?',
		delete_ok: '删除成功！',
		delete_no: '已取消删除！',
		SetAPI: '设置API',
		DeleteAPI: '删除API',
		bucangbeishu: '补仓倍数',
		marting: '智能马丁',
		wangge_f: '智能马丁',
		bottom: '底部追踪',
		wave: '海浪网格',
		wave_f: '海浪网格',
		Single: '单次循环',
		Continuous: '连续循环',
		APIAuthorization: 'API授权',
		// -----20221128 start----
		BRopen:'BR开通',
		okx:'OKX开通',
		huobi2:'火必开通',
		tip:'提示',
		warehouseClear:'是否确认一键清仓?',
		orderClear:'是否确认清理挂单?',
		ConfirmSwap:'确认兑换',
		ConfirmSwapDialog:'是否确认兑换?',
		beyondBalance:'超出可用余额',
		swapSuc:'兑换成功！',
		viewAll:'查看全部',
		Accountassets:'账户资产',
		openExchange:'开通交易所账号',
		systemTitle:'数字资产智能管理系统',
		FaceToFaceShare:'面对面扫码邀请',
		IOSdown:'IOS下载',
		androidDown:'安卓下载',
		iosDownErr:'IOS版 即将上线',
		tutorial:'教程',
		setSuc:'设置成功',
		representative:'业务代表',
		partner:'合伙人',
		closeAll:'一键关闭',
		openAll:'一键开启',
		AntiRiotFall: '防暴跌',
		CycleTime: '时间周期',
		UnravelingMode: '解套模式',
		fixedStopLoss: '固定止损',
		floatStopLoss: '浮动止损',
		InOperation: '运行中',
		Closed: '已关闭',
		unlock_price: '解套资金',
		routine: '常规网格',
		priceDB:'价格',
		priceDBWave:'价格区间智能设置',
		priceDBRoutine:'价格区间手动设置',
		orderDB:'挂单',
		orderDBWave:'极少挂单资金利用率高',
		orderDBRoutine:'挂单资金消耗巨大',
		plungeDB:'暴跌',
		plungeDBWave:'防暴跌监控模块自动检测风险自动启停',
		plungeDBRoutine:'无暴跌应对机制',
		uncoatDB:'解套',
		uncoatDBWave:'双重解套系统超强解套能力',
		uncoatDBRoutine:'无解套或减仓逻辑',
		geoDB:'等比等差',
		geoDBWave:'支持等比等差网格还支持指数级缩放网格',
		geoDBRoutine:'等比等差的常规价格网格套或减仓逻辑',
		saveImg:'保存至相册',

		// res err
		gasInsufficient:'用户燃料费不足',

		// -----20221128 end----

		// -----20210414 start----
		transfer_in: '转入',
		transfer_out: '转出',
		SuccessfulPurchase: '购买成功！',
		AvailableBalance: '可用余额',
		Processing: '处理中...',
		Stoping: '停止中...',
		Clearanceing: '清仓中...',
		GetParameters: '获取参数中...',
		qidong: '启动中...',
		qidongsuccess: '启动成功',
		FailedAcquireRobot: '获取机器人失败',
		Loading: '加载中...',
		SaveParametersSucceeded: '保存参数成功！',
		SaveParametersFailed: '保存参数失败！',
		SaveStartSuccessfully: '保存并启动成功！',
		CannotEmpty: '参数不能为空！',
		robot: '机器人',
		StopSuccess: '停止成功',
		PleaseSetTogetInitializationParameters: '请先点击设置，获取初始化参数',
		OnceMaxAction20: '一次不可操作20个交易对',
		SelectOneMore: '请至少选择一个交易对',
		successNumber: '成功个数',
		failNumber: '失败个数',
		SaveingParametersbatch: '批量保存参数中...',
		errorWithdrawAmount: '提币数量不对',
		// -----20210414 end----
		// -----20210413 start----
		SelectPosition: '选择仓位',
		Profits: '%盈利额',
		UserHostingAgreement: '《用户托管协议》',
		TrusteeshipRiskyShouldBeCaution: '托管有风险,使用需谨慎',
		ServiceRights: '服务权益',
		enteryourMobilenumber: '请填写手机号！',
		PleaseCheckUserAgreement: '请勾选《用户协议》',
		// RegistrationSuccessfulReturntoLogin: '注册成功！请返回登录页面登录！',
		RegistrationSuccessfulReturntoLogin: '注册成功！',
		PleaseEnterYourUsername: '请输入用户名',
		AnnouncementDetails: '公告详情',
		PasswordLogin: '密码登录',
		VerificationCodeLogin: '验证码登录',
		passwordMin8: '密码不能为空且不能小于8位',
		// ------20210413[end]-----
		// -----20200409 start----
		APIset: '设置API',
		codeHasSend: '验证码发送至：',
		enterAPIKey: '请输入API KEY',
		enterSecretKey: '请输入Secret KEY',
		enterPassphrase: '请输入Passphrase',
		setWord1: '进入{exchange}, 通过API菜单创建所需信息',
		setWord2: '复制创建的信息到上面对应输入框',
		setWord3: '点击确认, 并核对app首页底部资产',
		walletRecord: '钱包记录',
		fuelFee: '燃料费',
		totalReward: '资管总收益',
		today: '今日收益',
		total: '总：',
		addUp: '累计收益',
		toBeVIP: '马上成为VIP',
		setupMarting: '马丁设置',
		Onceloop: '单次循环',
		ci: '次',
		cycle: '周期',
		extentContinuousDecline: '连续下跌的幅度',
		MemberClub: '会员俱乐部',
		deadline: '到期',
		youarenotVip: '您还不是会员',
		handlingMembership: '办理会员',
		yes: '确定',
		chongbiAddress: '充币地址',
		choosebi: "选择币种",
		reciceMoneyAddress: '收款地址',
		reciveAccount: '接收账号',
		reciveAccountName: '接收账号名',
		tibiConfirmInfo: '提币信息确认',
		RiskStatement: '风险提示',
		riskTipsText: {
			one: '1、请仔细核对提币地址信息, 提币一旦完成,资产将无法追回 ;',
			two: '2、请警惕任何传销、非法集资、诈骗等违法行为, 防范“搬砖套利”、“带单操盘”等骗局, 避免造成财产损失 ;',
			three: '3、任何宣称为合作的投资均为诈骗, 您可以点击官方验证通道, 查验识别其是否为官方渠道 ;',
			four: '4、数字资产投资是高风险行为, 请注意识别传销币、山寨币等投资项目 ;',
			five: '5、如有任何疑问, 请在帮助中心联系客服解决 .',
		},
		tibiAddressError: '提币地址不对',
		checkNetwork: '请检查网络',
		SecurityVerification: '安全验证',
		verificationCode: '验证码',
		PasswordInput: '密码输入',
		VerificationCodeSentSuccess: '验证码发送成功',
		changeLoginPass: '修改登录密码',
		setTransferPass: '设置交易密码',
		setDefaultExchange: '设置默认交易所',
		inputLoginPwd: '请输入登录密码',
		changePwd: '修改密码',
		copyVersion: '版本号',
		weiXin: '微信',
		trusteeship: '托管',
		trusteeship_close: '停止托管',
		trusteeshipClose: '是否关闭当前套餐？',
		trusteeshipClose_yes: '是',
		trusteeshipClose_no: '否',
		trusteeshipClose_success: '关闭套餐成功！',
		trusteeshipOpen_success: '开通套餐成功！',
		trusteeshipPlease_check: '请先勾选用户托管协议！',
		uniswap: 'Uniswap',
		dealNow: '立即交易',
		noRobot: '暂无机器人',
		startNow: '立即开启',
		oneButtonReplenishment: '一键补仓(USDT)',
		oneButtonReplenishment1: '一键补仓(张)',
		cao_num: '超出持仓数量',
		ReplenishmentQuantity: '补仓数量:',
		SelloutQuantity: '卖出数量:',
		MartinDetails: '马丁详情',
		BottomTrackingDetails: '底部追踪详情',
		stopRobotFirst: '请先停止机器人',
		SuccessReplenish: '补仓成功',
		SuccessSellout: '卖出成功',
		failReplenish: '补仓失败',
		failSellout: '卖出失败',
		SuccessClearance: '清仓成功',
		failClearance: '清仓失败',
		OrderStatus: '订单状态',
		helpGuide: '帮助指南',
		resetPassword: '重置密码',
		nextStep: '下一步',
		enterMobileNumber: '请输入您的手机号',
		EnterReceivedVerificationCode: '输入收到的验证码',
		Resend: '重新发送',
		// -----20200409 end----
		historyRecord: '历史记录',
		descriPtion: '说明',
		filterType: '筛选类型',
		MyProfit: '我的盈利',
		todayProfit: '今日盈利',
		AccumulatedProfit: '累计盈利',
		order: '订单',
		date: '日期',
		switch_pack: '兑换套餐',
		DealFor: '交易对',
		running: '运行中',
		stop: '已停止',
		all: '全部',
		unconfig: '热门',
		manualBuchang: '手动补仓',
		manualMaichu: '手动卖出',
		savefail: "保存失败",
		exchange: "交易所",
		Profit: '盈利',
		Activation1code: '激活码（已经用）',
		MyActivationCode: '我的激活码',
		PurchaseQuantity: '购买数量',
		payment: '支付',
		PurchaseActivationCode: '购买激活码',
		BuyNow: '立即购买',
		setup: '设置',
		start_up: '启动',
		stop_it: '停止',
		assets: '资产',
		ChainType: '链类型',
		Receivingaddress: '收货地址',
		copy: '复制',
		explain: '请勿向上述地址充值任何非USDT-TRC20资产，否则资产将不可退回',
		cancel: '取消',
		title: '策略详情',
		PositionAmount: '持仓金额',
		AveragePositionPrice: '持仓均价',
		Numberofpositionsfilled: '已补仓次数',
		PositionQuantity: '持仓数量',
		CurrentPrice: '当前价格',
		Up: '利润率',
		StrategyRelated: '策略相关',
		OpeningAmount: '开仓金额',
		Replenishmenttimes: '补仓次数',
		Profitmargin: '止盈幅度',
		zhiyingcishu: '止盈次数',
		OneProfitmargin: '每单止盈幅度',
		Suofanglv: '宽度缩放率',
		Zuidabucangcishu: '最大补仓次数',
		Wanggekuandu: '网格宽度',
		Bucangjinersuofanglv: '补仓金额缩放率',
		gangganbeishu: '杠杆倍数',
		Profitstopcallback: '止盈回调',
		Marginofreplenishment: '补仓幅度',
		Makeupcallback: '补仓回调',
		Horizontalpushornot: '是否平推',
		pingtuifudu: '平推幅度',
		shoucibucang: '首次补仓',
		di2cibucang: '第2次补仓',
		di3cibucang: '第3次补仓',
		di4cibucang: '第4次补仓',
		di5cibucang: '第5次补仓',
		di6cibucang: '第6次补仓',
		di7cibucang: '第7次补仓',
		Cyclesetting: '周期设置',
		DeclineRange: '下跌幅度',
		Policyoperation: '策略操作',
		Policysettings: '策略设置',
		Clearingandselling: '清仓卖出',
		Wavegrid: '海浪网格',
		article: '资讯',
		WelcometoRainbow: '',
		Passwordlogin: '密码登录',
		SMSlogin: '验证码登录',
		Forgotpassword: '忘记密码？',
		LogintoagreeUserAgreement: '《用戶協議 》 登录即同意，《用户协议》',
		Obtain: '获取',
		Confirmpasswordagain: '再次确认密码',
		registered: '注册',
		invitefriends: '邀请好友',
		home: "首页",
		vcode: "验证码",
		Spot: '现货 ',
		USDTcontract: 'USDT合约',
		// Currency-basedcontract: '币本位合约',
		CurrencyBasedcontract: '币本位合约',
		Bottomloop: '底部循环',
		Position: '持仓',
		Floating: '浮盈',
		Thepositionhasbeencovered2times: '已补仓2次',
		Batchsettings: '批量设置',
		Batch_stop: '批量停止',
		Bulk_clearing: '批量清仓',
		Usedusdt: '已用usdt',
		Usdtavailable: '可用',
		AccountassetsUSDT: '账户资产（USDT)：',
		AccountassetsSFOW: '账户资产（SFOW)：',
		AccountassetsFOW: '账户资产（FOW)：',
		Topup200usdt: '充值200usdt',
		activation: '激活',
		ImmediatelyactivatetheRainbowintelligentquantitativerobot: '立即激活智能资管机器人',
		Todaysearnings: '今日收益',
		Teamtodaysearnings: '团队今日收益',
		DirectMembership: '直推会员列表',
		Directpushmember: '直推会员',
		activated: '已激活(人)',
		inactivated: '未激活(人)',
		Directpushtotalrevenue: '直推总收益',
		Directlypushtodaysearnings: '直推今日收益',
		Numberofteam: '团队人数',
		Totalteamrevenue: '团队总收益',
		sequence: '序列',
		accountnumber: '账号',
		Directpush: '直推',
		Team: ' 团队 ',
		save: "保存",
		Todayrevenue1: '当日收益',
		Todayrevenue: '今日收益',
		Totalrevenue: '总收益',
		Orderid: '订单ID ',
		Cumulativeincomedaily: '累计收益（每日)',
		Buyin: '买入 ',
		TransactionRecord: '交易记录',
		Sell: '卖出  ',
		Thenumberoftransactions: '成交数量',
		Averagetransactionprice: '交易均价',
		transactionfee: '交易费用 ',
		Handlingfee: '手续费',
		Log: '日志',
		OperationID: '订单ID',
		News: '资讯',
		Notice: '通知',
		news: '新闻',
		// Invitefriendstoenjoya30%bonus: '邀请好友一起享受30％的奖金',
		Invitefriendstoenjoya30bonus: '邀请好友一起享受30％的奖金',
		sendinvitationTofriends: '发送邀请给好友',
		Friendscompleteregistrationandactivation: '好友完成注册并激活',
		Getrewardsinproportion: '获得相应比例奖励',
		Myinvitationlink: '我的邀请链接',
		Myinvitationcode: '我的邀请码',
		Generateinvitationposter: '生成邀请海报',
		Popular: '热门',
		Pleaseentercurrency: '请输入币种',
		searchfor: '搜索',
		Batchclearance: '批量清仓',
		Bulkreplenishment: '批量补仓',
		Batchstart: '批量启动',
		// Motherboard-mainarea: '主板-主区',
		MotherboardMainarea: '主板-主区',
		InnovationZone: '创新区',
		parameter: '参数',
		Cycleway: '循环方式',
		Saveandstart: '保存并启动',
		Withdraw: '提币',
		Securitycenter: '安全中心',
		tool: '工具',
		Helpcenter: '帮助中心',
		aboutus: '关于我们',
		dropout: '退出',
		Helpdescription: '帮助说明',
		HelloIamXiaoRcanIhelpyouYoucanclickonthecorrespondingquestiontounderstandtheworkingprincipleofRainsintelligentquantitativerobot: '您好，我是小R，我能帮您吗？ 您可以单击相应的问题以了解 Rain 智能资管机器人的工作原理',
		NoviceOperationGuide: '新手操作指南',
		Rainsstrategyparameterfunctionsettinginstructions: 'Rain的策略参数功能设置说明',
		Rainsrecommendationrewardmechanism: 'Rain的推荐奖励机制',
		Aboutrates: '关于费率',
		Aboutdepositandwithdrawalfees: '关于充提扣费',
		Chainname: '链名称',
		// Pleasedonotdepositanynon-TRC20_USDTassetstoheaboveaddressotherwisetheassetswillnotberetrieved: '请勿向上述地址充值任何非TRC20_USDT资产，否则资产将不可找回',
		PleasedonotdepositanynonTRC20USDTassetstoheaboveaddressotherwisetheassetswillnotberetrieved: '请勿向上述地址充值任何非TRC20_USDT资产，否则资产将不可找回',
		Minimumwithdrawalamount10: '最小提币数量10',
		Minimumwithdrawalamount: '最小提币数量',
		// Note: TheminimumwithdrawalamountontheRainbowplatformis10usdtandthewithdrawalfeeis2upertransaction: '注意：Rainbow平台的最低提款金额为10usdt，提款手续费为2u/笔',
		zuyi: '注意：比特火箭平台的最低提款金额为',
		shouxufei: '提款手续费为',
		zhuan_shouxufei: '转账手续费为',
		shouxufei_danwei: '/笔',
		Arrivalquantity: '到账数量',
		Seagridy: '海网格',
		Continuousloop: '连续循环',
		begin: '开始',
		// Alreadyhaveanaccount?Tologin: '已有账号？去登陆',
		AlreadyhaveanaccountTologin: '已有账号？去登陆',
		YouNeedEmail: "为了提现交易等操作，请先设置邮箱",
		email: '邮箱',
		strategy_setup: '策略设置',
		defaultExchange: '默认交易所',
		defaultVcode: '默认验证码',
		defaultLang: '默认语言',
		VIPmember: 'VIP会员',
		vcode_title: '设置默认验证码',
		sms: '短信',
		vcode_msg: '提现和交易需要验证码，请选择默认验证码发送方式',
		savesucc: '保存成功',
		defaultExchange_msg: '选择您最常用的交易所',
		huobi: '火必',
		bian: '币安',
		OKEx: 'OKX',
		bitrocket: '比特火箭',
		lang: '设置语言',
		en: '英语',
		zh: '中文',
		lang_msg: '请选择默认界面和操作语言',
		checkversion: '检查版本',
		currency: '交易对',
		strategy: '策略',
		wangge: '智能马丁',
		wangge2: '底部追踪',
		wangge3: '海浪网格',
		wangge_m: '海浪趋势',
		quant: '资管',
		trade: '交易',
		aritcle: '资讯',
		my: '我的',
		contract: '合约',
		community_profit: '社区收益',
		my_profit: '个人收益',
		begin_quant: '开始资管',
		quant_welcome: '欢迎使用TBA合约资管系统',
		myteam: '我的团队',
		follow_setup: '跟随设置',
		follow_methods: '跟随方式',
		follow_all: '跟随所有币种',
		token_select_setting: '币种设置',
		asset_scale: '净值缩放',
		select_trade_strategy: '选择交易策略',
		Bill: '账单',
		transaction: '交易',
		// AnnouncementonthelaunchofRainbowQuantitativeRobot..: '关于Rainbow资管机器人上线公告...',
		AnnouncementonthelaunchofRainbowQuantitativeRobot: '关于资管机器人上线公告...',

	},
	xinzeng: {
		huanbi: "资产兑换",
		qianbao: "钱包",
		faxian: "发现",
		xiaoxi: "消息",
		wode: "我的",
		bangdingtibidizhi: "请绑定提币地址",
		tibidizhi: "提币地址",
		xuanzebi: "选择更多币种",
		tibi: "提币",
		buzhichi: "不支持通证类型",
		fuzhichengg: "复制成功",
		chongbi: "充币",
		zichan: "资产",
		exchange_tt: "兑换",
		shandui: "闪兑",
		zhuanz: "转账",
		zanweikaif: "暂未开放",
		xi: "将会抵押到虚拟银行,赎回时有2周锁定期",
		wodezichan: "我的资产",
		keyongzichan: "可用资产",
		chankanxiangqing: "查看详情",
		chakangengduo: "查看更多",
		zhuanzhang: "转账",
		zhiyawakuang: "质押挖矿",
		zhengben: "账本",
		defipool: "挖矿理财",

		shoukuan: "收款",
		chuangshixinbi: "创世新币",
		hongbao: "红包",
		shengou: "申购",
		zuixinhangqing: "最新行情",
		zanweikaitobng: "暂未开通"

	},
	tankuang: {
		no: "取消",
		yes: "确认"
	},
	login: {
		// ----20210409 start---- //
		xiazai: '下载APP',
		welcome: '',
		userLawB: '登录即同意',
		userLaw: '《用户协议》',
		PleaseEnterRightPhoneNumber: '请输入正确的手机号',
		PleaseEnterRightVerificationCode: '请输入正确的验证码',
		registAccount: '注册账号',
		PleaseEnterYourPasswordAgain: '请再次确认密码',
		PleaseEnterYourCode: '请输入邀请码',
		AgreeLaw: '我同意',
		and: '和',
		mianzetiaokuan: '《免责条款》',
		regist: '注册',
		hasAccount: '已有账号？去',
		// ----20210409 end---- //
		emailorphone: '手机号或邮箱',
		VerificationCodeLogin: '验证码登录',
		PasswordToLogin: '密码登录',
		PleaseEnterYourCellPhoneNumber: '请输入手机号',
		PleaseEnterYourCellEmailNumber: '请输入邮箱',
		PleaseEnterVerificationCode: '请输入验证码',
		getCode: '验证码',
		login: '登录',
		login_false: '用户名或密码错误',
		login_failed: '验证码不对',
		No_register: '请先注册',
		WalletUsersLogInDirectly: '钱包用户直接登录',
		PleaseEnterYourMobilePhoneNumber: '请输入账号/邮箱',
		PleaseEnterYourPassword: '请输入密码(8位以上数字或字母)',
		RegisteredAccountNumber: '注册账号',
		ForgetThePassword: '忘记密码',
		PleaseInputMobilePhoneNumber: '请输入账号',
		// 请输入手机号',
		PleaseInputAPassword: '请输入密码',
		PleaseEnterEheVerificationCode: '请输入验证码',
		MobileRegistration: '手机号注册'


	},

	common: {
		home: '首页',
		personal: '个人中心',
		lang: '语言 ',
		Chinese: '中文',
		English: '英文',
		loading: '加载中',
		affirm: '确认',
		cancel: '取消'
	},
	home: {
		MyAssets: '我的资产',
		redPacket: '红包',
		mining: '挖矿',
		blotting: '印迹',
		Ticket: '票务',
		college: '学院',
		more: '更多',
		TPUSparkPlugInSAASMarketplace: 'TPU星火插件SAAS市场',
		ItCurrently: '致力于为区块链项目提供落地应用插件，当前开放红包插件',
		PartnerRecruitment: '合伙人招募',
		Welcome: '欢迎加入我们成为技术、产品、运营、社区合伙人，一起来推进整个SAAS平台建设'
	},
	liaotian: {
		RedEnvelopeHall: '红包大厅',
		transferAccounts: '转账',
		redPacket: '红包',
		orderToBetter: '已抢',
		Bidding: '发包',
		property: '资产',
		GamesArePlayed: '玩法',
		unit: '元'
	},
	dig: {
		NervePledgesForMining: 'TPU质押挖矿',
		MyPledge: '我的质押',
		ThePledgeToRecord: '质押记录',
		ToPledge: '去质押',
		beFrom: '来自',
		greeting: '祝福语',
		YouGet: '您获得',
		command: '口令',
		RedEnvelope: '发红包',
		YouHaveOpenedTheRedEnvelope: '您已拆红包',
		OpenRedEnvelope: '拆红包',
		TurnRedEnvelopes: '转红包',
		PleaseEnterRedEnvelope: '请输入红包指令',
		altogether: '共',
		individual: '个',
		HaveBeenReceiving: '已被领取',
		snag: '抢到',
		phone: "请绑定手机",
		password: "请设置交易密码"

	},
	fahongbao: {
		SendingRedEnvelope: '发送红包',
		NeedAPassword: '需要口令',
		need: '需要',
		withNoNeed: '不需要',
		SetThePassword: '设置口令',
		ThroughTheType: '通证类型',
		BonusAmount: '红包金额',
		pleaseEnter: '请输入',
		TheNumberOfRedPackets: '红包个数',
		redEnvelopeType: '红包类型',
		random: '随机',
		goHalves: '平分',
		CoverGreetings: '封面祝福语',
		serviceCharge: '服务费',
		WhetherThePublic: '是否公开',
		open: '公开',
		privacy: '私密',
		balance: '余额',
		NotEnvelope: '不足支付支付红包金额',
		Recharge: '充值',
		GenerateRedEnvelope: '生成红包',
		MyRecord: '我的记录',
		ContactUs: '联系客服',
		PleaseEnterTradingPassword: '请填写密码',
		SixPassword: '请填写6位纯数字',
		PleaseEnterSixTransactionPassword: '请输入6位数字的交易密码',
		hint: '提示',
		InsufficientBalancePlease: '余额不足',
		PleaseEnterYourPassword: '请输入口令',
		PleaseSelectThePass: '请选择通证',
		totalAmountCannotBeEmpty: '红包总额不能为空',
		numberOfRedEnvelopesCannotBeEmpty: '红包个数不能为空',
		PleaseEnterYourUsernameAndPassword: '请输入用户名和密码',
		affirm: '确认',
		cancel: '取消'
	},
	coffer: {
		MyAssets: '我的资产',
		cloudAssets: '云端资产',
		availableCapital: '可用资产',
		totalAssets: '资产总额',
		frozenAssets: '冻结资产',
		withdraw: '提现',
		LocalAssets: '本地资产',
		FunctionalIntegration: '功能整合中'
	},
	explain: {
		GameShows: '玩法说明',
		PasswordRedEnvelope: '口令红包：用户输入正确的口令才可以拆红包',
		OrdinaryHongbao: '普通红包：先到先得',
		UnloggedUsers: '未登录用户需要输入手机号和验证码才能领取红包',
		RedEnvelopeIsALightweight: '红包是一个轻量级IM，敬请期待基于IM的互动玩法'
	},
	myPledge: {
		MyPledge: '我的质押',
		operate: '操作',
		AdditionalMortgage: '追加抵押',
		pledgeToRedeem: '质押赎回',
		ExpectedEarnings: '预计今日收益',
		pledgeNumber: '质押数量',
		accumulatedEarnings: '累计收益',
		pledgeOfDays: '质押天数',
		applicationDate: '申请日期',
		Number: '数量',
		currency: '币种',
		status: '状态',
		packUp: '收起',
		NoPledgeHasBeenCompleted: '质押完成时间为24小时哦',
		PleaseEnterTransactionPassword: '请输入交易密码',
		PleaseEnterAdditionalQuantity: '请输入追加数量',
		SuccessfulPledgeRedemption: '质押赎回成功',
		gainRecording: '领取收益',
		todayEarnings: '预计今日收益'
	},
	redemptionRecord: {
		pledgeToRecord: '质押记录',
		NoMore: '没有更多了',
		serialNumber: '序号',
		operate: '操作',
		Number: '数量',
		status: '状态',
		IsThePledge: '正在质押',
		BeganToYield: '开始收益',
		RedemptionComplete: '赎回完成',
		ApplicationDate: '申请时间',
		ReturnsTheTime: '收益时间'
	},
	pledge: {
		DigTheNumber: '挖矿数量',
		SinceThe50: '个起',
		all: '全部',
		usable: '可用',
		HaveAlreadyPledged: '已经质押',
		DueOnDemand: '活期',
		predict: '预计',
		StartCalculatingReturns: '开始计算收益',
		ExpectedMonthlyEarnings: '预计每年收益',
		ToPledge: '去质押',
		PleaseEnterTransactionPassword: '请输入交易密码',
		pledgeSuccess: '质押成功'
	},
	track: {
		blotting: '印迹',
		BlockchainSourceTrackingCardStoragePlatform: '区块链溯源存证平台',
		ProductDevelopmentAndDebuggingInProgress: '产品开发调试中'
	},
	ticket: {
		ticketService: '票务',
		LetDigital: '让数字货币支持票务购买，比如火车票、机票、电话卡、加油卡',
		SupportNULS: '支持NULS、TPU、ETH等等',
		SpecialSupportForPerformance: '对演出票务特别支持，能快捷发行和流通数字票务。有演出票务需求场景的朋友们我们一起沟通',
		SupportForTrainTickets: '火车票、机票等的支持在从老版本迁移测试'
	},
	tpuschool: {
		BlockchainCorrelation: '区块链相关',
		learningMaterials: '学习资料',
		caseTeaching: '案例教学',
		solution: '解决方案',
		InCollectingData: '收集资料中'
	},
	more: {
		more: '更多',
		SupportTheSpread: '支持真实、有效声音的传播',
		SupportThePromotion: '支持先锋科技和文化的推广'
	},
	geren: {
		change: "切换语言",
		personalCenter: '个人中心',
		MyAssets: '我的资产',
		redEnvelopeToRecord: '红包记录',
		MentionFillingRecord: '充提记录',
		gainRecording: '收益记录',
		personalSetting: '个人设置',
		InviteRewards: '邀请奖励',
		IWantToFeedback: '我要反馈',
		switchAccount: '切换账号',
		logOut: '退出登录',
		phoneNumber: '手机号',
		email: '邮箱地址',
		site: '地址',
		cashFlow: '现金流水'
	},
	redRecode: {
		redRecode: '红包记录',
		ISent: '我发出的',
		NoMore: '没有更多了',
		redPacket: '红包',
		individual: '个',
		get: '领取',
		status: '状态',
		InTheDistributed: '分发中',
		HasRecycled: '回收中',
		time: '时间',
		operation: '操作',
		examine: '查看',
		withdraw: '撤回',
		IReceivedThe: '我收到的',
		hint: '提示',
		WithdrawTheSuccess: '撤回成功'
	},
	cashOut: {
		MentionFillingRecord: '充值记录',
		NoMore: '没有更多了',
		serialNumber: '序号',
		token: '通证',
		number: '数量',
		time: '时间',
		WithdrawalRecord: '提现记录',
		status: '状态',
		clickToView: '点击查看'
	},
	shouyiRecord: {
		gainRecording: '收益记录',
		NoMore: '没有更多了',
		operation: '操作',
		serialNumber: '序号',
		principal: '本金',
		rateInterest: '利率',
		earnings: '收益',
		data: '日期',
		GetBenefits: '领取收益',
		GetTheSuccess: '领取成功'
	},
	Uset: {
		set: '设置',
		SetCellPhoneNumber: '绑定手机',
		SetEmail: '绑定邮箱',
		updateEmail: '修改邮箱',
		email: '邮箱',

		loginPassword: '登录密码',
		transactionPassword: '交易密码',
		nicknameIsSet: '昵称设置',
		PleaseEnterNewNickname: '请输入新的昵称',
		PictureUpload: '头像上传',
		affirm: '确认',
		cancel: '取消'
	},
	sPhone: {
		email: '邮箱地址',
		phoneNumber: '手机号',
		PleaseEnterYourCellPhoneNumber: '请输入手机号',
		messageAuthenticationCode: '验证码',
		pleaseMessageAuthenticationCode: '请输入验证码',
		getCode: '获取验证码',
		SetCellPhoneNumber: '绑定手机',
		SetEmail: '绑定邮箱',
		YouNeed: '提现和交易需要接收验证码，请先设置正确的手机号码',
		WrongCellPhoneNumber: '手机号码有误',
		verificationCode: '手机验证码不能为空',
		phoneNumberHasBeenSetSuccessfully: '手机号设置成功'
	},
	sPwd: {
		SetTradePassword: '设置登录密码',
		messageAuthenticationCode: '验证码',
		PleaseVerificationCode: '请输入验证码',
		getCode: '获取验证码',
		newPassword: '新密码',
		pleaseNewPassword: '请输入新密码',
		affirmPassword: '确认密码',
		PleaseEnterPasswordAgain: '请再次输入密码',
		setPassword: '设置密码',
		SMSVerificationCodeCannotBeEmpty: '验证码不能为空',
		pleaseAffirmPassword: '请确认密码',
		twoPasswordsDoNotMatch: '两次密码不一致',
		setPasswordSuccess: '设置密码成功',
		email: '邮箱地址',

		phoneNumber: '手机号'
	},
	sPay: {
		TradePasswordBinding: '交易密码绑定',
		messageAuthenticationCode: '验证码',
		PleaseSMSVerificationCode: '请输入验证码',
		getCode: '获取验证码',
		TradePassword: '请输入交易密码',
		pleaseEnterTradePassword: '请输入交易密码',
		ConfirmTransactionPassword: '请输入确认密码',
		pleaseAffirmTradePassword: '请确认交易密码',
		BindingTradePassword: '绑定交易密码',
		PleaseEnterCorrectVerificationCode: '请输入正确的验证码',
		PleaseEnterCorrectTransactionPassword: '请输入正确的交易密码',
		PleaseEnterCorrectTransactionEmail: '请输入正确的邮箱',
		emailSame: '新邮箱与原邮箱重复',
		twoPasswordsDoNotMatch: '两次密码不一致',
		hint: '提示',
		TransactionPasswordChangedSuccessfully: '交易密码修改成功',
		affirm: '确认',
		email: '邮箱地址',

		phoneNumber: '手机号'
	},
	team: {
		InviteRewards: '邀请奖励',
		MyInvitation: '我的邀请',
		InviteLink: '邀请链接',
		InviteCode: '邀请码',
		CopyInvitationAddress: '复制邀请地址',
		CopyInvitationCode: '复制邀请码',

		FaceFaceInvitation: '面对面邀请',
		SweepCodeToInvite: '扫码邀请',
		MyReward: '我的奖励',
		InviteNumber: '邀请人数',
		InviteReward: '邀请奖励',
		rankingList: '排行榜',
		redPacket: '红包',
		individual: 'individual',
		time: '时间',
		hint: '提示',
		affirm: '确认',
		copySuccess: '复制成功'
	},
	feedback: {
		coupleBack: '反馈',
		WelcomeCommunicateWithTheTeam: '欢迎和团队沟通',
		PleaseEnterTheCommunicationItems: '请输入沟通事项',
		ImmediatelySend: '马上发送',
		FeedbackContentCannotBeEmpty: '反馈内容不能为空',
		FeedbackSuccess: '反馈成功'
	},
	cashFlow: {
		cashFlow: '现金流水',
		NoMore: '没有更多了',
		serialNumber: '序号',
		operate: '操作',
		flow: '流水',
		data: '日期',
		GetTheSuccess: '领取成功'
	},
	share: {
		laizi: "来自",
		de: "的红包",
		zong: "总额",
		chai: "拆红包",
		fas: "发送给朋友",
		baocun: "保存成分享图",
		chang: "长按上面的图 保存到本地"
	},
	recharge: {
		one: "打币地址",
		min: "最小存入",
		fuzhi: "复制地址"
	},
	tixian: {
		shenqing: "提现申请",
		one: "提现通证",
		two: "可用数量",
		dongjie: "冻结数量",
		duanxin: "验证码",
		tixian: "提现数量",
		jiaoyi: "交易密码",
		fuwu: "服务费选择",
		bangding: "绑定提币地址",
		fasong: "发送验证码",
		fasongqingiqu: "发送请求",
		qingone: "请输入验证码",
		qingtwo: "请输入提现数量",
		qingthree: "请输入交易密码",
		tanone: "提现数量小于最低提现数量",
		tantwo: "提现数量必须大于零",
		tanthree: "提现数量大于可用余额",
		tanfour: "提现",
		tanfive: "请先设置提币地址",
		tansix: "提现成功, 将在24小时内处理",
		tansevw: "请先设置交易密码",
		zuidi: "最低提现数量",
		keyi: "可提现数量",
		fuwufei: "服务费",
		shoudao: "收到：",
		yzmerr: "验证码错误",
		pwderr: "交易密码错误",
		tberr: "提币失败"
	},
	bangdingdizhi: {
		one: "绑定地址",
		two: "验证码",
		three: "请输入验证码",
		huoquyanzhnegm: "获取验证码",
		jiaoyi: "交易密码",
		qingshuru: "请输入交易密码",
		tixian: "提现地址",
		qingtwo: "请输入绑定地址",
		dizhi: "地址备注",
		qingfour: "请输入地址备注",
		bangdingdizhi: "绑定地址",
		tanone: "验证码不能为空",
		tantwo: "请输入地址",
		tanthree: "请输入地址备注",
		yes: "绑定成功"
	},
	phoneCode: {
		A: ['阿富汗 +93+4', '阿尔巴尼亚 +355+8', '阿尔及利亚 +213+12', '美属萨摩亚 +684+16', '安道尔 +376+20', '安哥拉 +244+24', '安圭拉岛 +1264+660', '安提瓜和巴布达 +1268+28', '阿根廷 +54+32', '亚美尼亚 +374+51', '澳大利亚 +61+36', '奥地利 +43+40', '阿塞拜疆 +994+31'],
		B: ['巴哈马 +1242+44', '巴林 +973+48', '孟加拉国 +880+50', '巴巴多斯 +1246+52', '白俄罗斯 +375+112', '比利时 +32+56', '伯利兹 +501+84', '贝宁 +229+204', '百慕大群岛 +1441+60', '玻利维亚 +591+68', '博茨瓦纳 +267+72', '巴西 +55+76', '文莱 +673+96', '保加利亚 +359+100', '布基纳法索 +226+854', '布隆迪 +257+108'],
		C: ['柬埔寨 +855+116', '喀麦隆 +237+120', '加拿大 +1+124', '开曼群岛 +1345+136', '中非共和国 +236+140', '乍得 +235+148', '智利 +56+152', '中国 +86+156', '哥伦比亚 +57+170', '刚果 +242+178', '库克群岛 +682+184', '哥斯达黎加 +506+188', '科特迪瓦 +225+384', '古巴 +53+192', '塞浦路斯 +357+196', '捷克共和国 +420+203'],
		D: ['丹麦 +45+208', '吉布提 +253+262', '多米尼加共和国 +1890+214'],
		E: ['厄瓜多尔 +593+218', '埃及 +20+818', '萨尔瓦多 +503+222', '爱沙尼亚 +372+233', '埃塞俄比亚 +251+231'],
		F: ['斐济群岛 +679+242', '芬兰 +358+246', '法国 +33+250', '法属圭亚那 +594+254', '法属波利尼西亚 +689+258'],
		G: ['加蓬 +241+266', '冈比亚 +220+270', '乔治亚 +995+268', '德国 +49+276', '加纳 +233+288', '直布罗陀 +350+292', '希腊 +30+300', '格林纳达 +1809+308', '关岛 +1671+316', '危地马拉 +502+320', '几内亚 +224+324', '圭亚那 +592+328'],
		H: ['海地 +509+332', '洪都拉斯 +504+340', '香港 +852+344', '匈牙利 +36+348'],
		I: ['冰岛 +354+352', '印度 +91+356', '印度尼西亚 +62+360', '伊朗 +98+364', '伊拉克 +964+368', '爱尔兰 +353+372', '以色列 +972+376', '意大利 +39+380'],
		J: ['牙买加 +1876+388', '日本 +81+392', '约旦 +962+400'],
		K: ['哈萨克斯坦 +7+398', '肯尼亚 +254+404', '朝鲜 +850+408', '韩国 +82+410', '科威特 +965+414', '吉尔吉斯斯坦 +331+417'],
		L: ['老挝 +856+418', '拉脱维亚 +371+428', '黎巴嫩 +961+422', '莱索托 +266+426', '利比里亚 +231+430', '利比亚 +218+434', '列支敦士登 +423+438', '立陶宛 +370+440', '卢森堡 +352+442', '卢旺达 +250+646'],
		M: ['澳门 +853+446', '马达加斯加 +261+450', '马拉维 +265+454', '马来西亚 +60+458', '马尔代夫 +960+462', '马里 +223+466', '马耳他 +356+470', '马提尼克岛 +596+474', '毛里求斯 +230+480', '墨西哥 +52+484', '摩尔多瓦 +373+498', '摩纳哥 +377+492', '蒙古 +976+496', '蒙特塞拉特 +1664+500', '摩洛哥 +212+504', '莫桑比克 +258+508', '缅甸 +95+104'],
		N: ['纳米比亚 +264+516', '瑙鲁 +674+520', '尼泊尔 +977+524', '荷兰 +31+528', '荷属安的列斯群岛 +599+530', '新西兰 +64+554', '尼加拉瓜 +505+558', '尼日尔 +227+562', '尼日利亚 +234+566', '挪威 +47+578'],
		O: ['阿曼 +968+512'],
		P: ['巴基斯坦 +92+586', '巴拿马 +507+591', '巴布亚新几内亚 +675+598', '巴拉圭 +595+600', '秘鲁 +51+604', '菲律宾 +63+608', '波兰 +48+616', '葡萄牙 +351+620', '波多黎各 +1787+630'],
		Q: ['卡塔尔 +974+634'],
		R: ['留尼汪岛 +262+638', '罗马尼亚 +40+642', '俄罗斯 +7+643'],
		S: ['圣卢西亚 +1758+662', '圣文森特和格林纳丁斯 +1784+670', '萨摩亚 +685+882', '圣马力诺 +378+674', '圣多美和普林西比 +239+678', '沙特阿拉伯 +966+682', '塞内加尔 +221+686', '塞舌尔 +248+690', '塞拉利昂 +232+694', '新加坡 +65+702', '斯洛伐克 +421+703', '斯洛文尼亚 +386+705', '所罗门群岛 +677+90', '索马里 +252+706', '南非 +27+710', '西班牙 +34+724', '斯里兰卡 +94+144', '苏丹 +249+736', '苏里南 +597+740', '斯威士兰 +268+748', '瑞典 +46+752', '瑞士 +41+756', '叙利亚 +963+760'],
		T: ['台湾 +886+158', '塔吉克斯坦 +992+762', '坦桑尼亚 +255+834', '泰国 +66+764', '多哥 +228+768', '汤加 +676+776', '特立尼达和多巴哥 +1809+780', '突尼斯 +216+788', '土耳其 +90+792', '土库曼斯坦 +993+795'],
		U: ['乌干达 +256+800', '乌克兰 +380+804', '阿拉伯联合酋长国 +971+784', '英国 +44+826', '美国 +1+840', '乌拉圭 +598+858', '乌兹别克斯坦 +998+860'],
		V: ['委内瑞拉 +58+862', '越南 +84+704'],
		Y: ['也门 +967+887'],
		Z: ['赞比亚 +260+894', '津巴布韦 +263+716'],
	}
}
