// const domain = 'http://api.yqkkn.com/';
// const domain = 'https://bitrocket.light22.net/';
const domain = 'https://Bitrocketapi.light22.net/';
// const domain =
//   process.env.NODE_ENV !== "production" ? "" : "http://abcapi.lightbot.world/";
const loginUrl = domain + "";
const psdCheck = domain + "redpkt/index/sendpkt";
// 默认配置
global.defConfig = {
  avatar: "//s.yqkkn.com/samo.png"
};
export default {
  domain,
  loginUrl,
  psdCheck
};
